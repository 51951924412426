import React from "react"
import {graphql} from "gatsby"
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';
import SEO from '../components/seo'

export default function Template({
                                   data, // this prop will be injected by the GraphQL query below.
                                 }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <>
      <SEO title={frontmatter.title} description={frontmatter.description}/>
      <div className="body_wrapper">
        <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto" fullRedirect={true}/>
        <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png"
                    Ptitle={frontmatter.title}
                    Pdescription={frontmatter.subtitle}/>
        <section className={frontmatter.className + ' sec_pad'}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div dangerouslySetInnerHTML={{ __html: html }}/>
              </div>
            </div>
          </div>
        </section>
        <Footer FooterData={FooterData}/>
      </div>
    </>
  )
}
export const pageQuery = graphql`
  query MyQuery($path: String!)  {
    markdownRemark(frontmatter: { pathId: { eq: $path } }) {
      html
      frontmatter {
        path
        date
        title
        subtitle
        className
        description
      }
    }
  }
`
